<template>
  <table-view
    :total="total"
    :query-info.sync="queryInfo"
    v-loading="loading"
    @reload-table="renderTable"
  >
    <template #header>
      <view-search-form
        ref="searchFormRef"
        :tool-list="['keyword']"
        keyword-placeholder="姓名"
        :queryInfo.sync="queryInfo"
        @on-search="renderTable(1)">
        <el-form-item label="用户类型">
          <el-select v-model="queryInfo.userType" size="small" clearable>
            <el-option label="教职工" :value="3" />
            <el-option label="学生" :value="2" />
          </el-select>
        </el-form-item>
      </view-search-form>
    </template>
    <!-- body -->
    <el-table :data="tableData" style="width: 100%" border stripe>
      <el-table-column type="index" label="ID" width="50" />
      <el-table-column prop="realName" label="姓名" width="150" />
      <el-table-column prop="cardNum" label="身份证号" min-width="170" />
      <el-table-column prop="phone" label="手机号码" width="150" />
      <el-table-column prop="cancelTime" label="账号注销时间" min-width="140" />
      <el-table-column label="操作" width="120" fixed="right" align="center">
        <template v-slot="{row}">
          <el-button type="primary" size="mini" v-permission="['sys:user:edit']" @click="cancelLogOff(row)">
            撤回注销操作
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </table-view>
</template>

<script>
import tableView from '@/vue/mixins/table-view'
import { cancelUserLogOfApi, pageCancelUserApi } from '@/api/system/log-off-manage-api'

export default {
  name: 'logOffManage',
  mixins: [tableView],
  data () {
    return {
      queryInfo: {
        userType: 2 // 3-教师，2-学生，不可改
      }
    }
  },
  methods: {
    // 获取用户列表
    async renderTable (pageNum) {
      await this.getTableData(pageCancelUserApi, pageNum)
    },
    cancelLogOff (row) {
      this.$confirm(`是否确认撤回用户-${row.realName}(身份证：${row.cardNum})的注销账号操作？`, '撤回注销').then(async () => {
        try {
          await cancelUserLogOfApi(row.id)
          this.$message.success('已成功撤回该用户的销户操作！')
          await this.renderTable(1)
        } catch (e) {
          if (!e) {
            this.$message.error('操作失败，请重试！')
          }
        }
      })
    }
  }
}
</script>
