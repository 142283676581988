import request from '@/service/request'

/**
 * 销户管理
 */
// 获取销户用户列表 page
export const pageCancelUserApi = (params) => {
  return request.post('/sys/user/cancel/page', params)
}

// 销户操作 撤回销户API
export const cancelUserLogOfApi = (id) => {
  return request.post(`/sys/user/saveCancel/${id}`)
}
